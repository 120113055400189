import './App.css';

function App() {
  return (
    <div className="App">
      <h1>
      welcome to property-sucnha-app
      </h1>
    </div>
  );
}
  
export default App;
